@import '~@vg-constellation/styles/c11n';
@import '~@vg-constellation/styles/styles.css';
@import '~@vg-constellation/styles/grid';
@import '~@vg-constellation/styles/typography';
@import '~@vg-constellation/styles/breakpoints';

@import './styles/table';

$static-url: 'https://digital-advisor.vanguard.com/static';
@import url('#{$static-url}/fonts/fonts.css');

.cross-icon {
    color:white;
    line-height:100px;
}

.c11n-modal__content{
    overflow: hidden !important;
}

.c11n-link--regular {
    padding-bottom: 0.1875rem !important;
    font-weight: 400 !important;
}

.c11n-link.show-more-link {
    cursor: pointer !important;
    line-height: 3 !important;
}

.c11n-link.show-more-link:hover {
    color: #040505 !important;
}

.hide-select-label-and-error c11n-label, 
.hide-select-label-and-error c11n-hint-error {
    display: none;
}

c11n-select {
    min-height: 1rem !important;
}

.c11n-table__thead-th--active-sort.ascending .c11n-link--icon:hover svg use:nth-child(1) {
    fill: #145bff !important;
}
.c11n-table__thead-th--active-sort.ascending .c11n-link--icon:hover svg use:nth-child(2) {
    fill: #cbcece !important;
}

.c11n-table__thead-th--active-sort.descending .c11n-link--icon:hover svg use:nth-child(1) {
    fill: #cbcece !important;
}
.c11n-table__thead-th--active-sort.descending .c11n-link--icon:hover svg use:nth-child(2) {
    fill: #145bff !important;
}

.c11n-close-button svg use {
fill: #c20029 !important;
}
.error-modal .c11n-modal--default .c11n-modal__document {
    border-left: .25rem solid #ba3d21 !important;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: $c11n-font-family-sans;
}

.vgn-skip-navigation,
.vgn-skip-navigation:focus,
.vgn-skip-navigation:active {
  display: none !important;
}

#app-skipnav {
  position: absolute;
  overflow: hidden;
  top: -999px;
}

#app-skipnav:focus,
#app-skipnav:active {
  background-color: white;
  color: black;
  height: auto;
  left: 4px;
  padding: 6px 8px;
  top: 4px;
  width: auto;
  outline: 2px solid $focus-color;
  z-index: 999;
  border: none;
}

#app-main-content {
  outline: none;
}


  body,
  html {
    margin: 0;
    padding: 0;
    font-family: $c11n-font-family-sans;
  }
  
  .vgn-skip-navigation,
  .vgn-skip-navigation:focus,
  .vgn-skip-navigation:active {
    display: none !important;
  }
  
  #app-skipnav {
    position: absolute;
    overflow: hidden;
    top: -999px;
  }
  
  #app-skipnav:focus,
  #app-skipnav:active {
    background-color: white;
    color: black;
    height: auto;
    left: 4px;
    padding: 6px 8px;
    top: 4px;
    width: auto;
    outline: 2px solid $focus-color;
    z-index: 999;
    border: none;
  }
  
  #app-main-content {
    outline: none;
  }

  .selectViewDropdown .c11n-dropdown__trigger {
    overflow: hidden !important;
  }

  .selectViewDropdown .c11n-dropdown__trigger:focus-within{
    outline: solid 0.125rem #0f62c5 !important;
    outline-offset: 0.125rem !important;
  }

// Small Mobiles

@media only screen and (max-width: 321px){
    .c11n-close-button{
        background-color: #ffffff !important;
        color: #c20029;
        box-shadow: 2px 2px 4px #717777;
        border-radius:100px;
        width:100px;
        height:100px;
        text-align:center;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(10%, -20%) !important;
    }
  }
  .c11n-nav-overflow__menu.c11n-menu__menu {
      left: auto !important;
      right: 0 !important;
  }
